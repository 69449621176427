import React, { useState, useEffect } from "react";
import axios from "axios";

const Articles = ({ username }) => {
  const [articles, setArticles] = useState([]);
  const [isArticlePresent, setIsArticlePresent] = useState(false);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `https://api.rss2json.com/v1/api.json?rss_url=https://pradhanadi.medium.com/feed`
        );
        if (response.data.items.length !== 0) {
          const articlesWithImages = response.data.items.map((article) => ({
            ...article,
            image: extractImageFromContent(article.content),
            preview: generatePreview(article.content),
          }));
          setArticles(articlesWithImages);
          setIsArticlePresent(true);
        } else {
          setArticles([]);
          setIsArticlePresent(false);
        }
      } catch (err) {
        setArticles([]);
        setIsArticlePresent(false);
      }
    };

    fetchArticles();
  }, [username]);

  const extractImageFromContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const img = doc.querySelector("img");
    return img ? img.src : null;
  };

  const generatePreview = (content) => {
    const plainText = content.replace(/<[^>]+>/g, "");
    const words = plainText.split(/\s+/).slice(0, 50);
    return words.join(" ") + (plainText.split(/\s+/).length > 100 ? "..." : "");
  };

  return (
    <>
      {isArticlePresent ? (
        <div>
          <div className="section">
            <div className="section-title">Articles</div>
            <div className="section-content articles">
              {articles.map((article) => (
                <div key={article.guid} className="article">
                  <div className="article-content">
                    <h3>{article.title}</h3>
                    <p>
                      Published Date:{" "}
                      {new Date(article.pubDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </p>
                    <p className="article-preview">{article.preview}</p>
                    <span className="category-chips">
                      {article.categories.map((category) => (
                        <span className="py-1 px-3" key={category}>{category}</span>
                      ))}
                    </span>
                    <a
                      href={article.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="highlight-link"
                    >
                      Read on Medium
                    </a>
                  </div>
                  {article.image && (
                    <img
                      src={article.image}
                      alt={article.title}
                      className="article-img"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Articles;
