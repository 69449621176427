export default function Experience() {
  const jobs = [
    {
      company: "Truminds Software System",
      duration: "Aug 2021 - Present",
      website: "https://www.truminds.com",
      position: "Senior Software Engineer",
    },
    {
      company: "IoT83",
      duration: "Feb 2021 - July 2021",
      website: "https://www.iot83.com/",
      position: "Senior Software Engineer",
    },
    {
      company: "ProfEdge Solutions",
      duration: "June 2019 - Jan 2021",
      website:
        "https://www.linkedin.com/company/profedge-solutions-pvt-ltd-/about/",
      position: "Software Engineer",
    },
    {
      company: "Arohar Technologies",
      duration: "Nov 2018 - April 2019",
      website: "https://www.arohar.com/",
      position: "Software Engineer",
    },
  ];

  return (
    <div className="section experience">
      <div className="section-title">Experience</div>
      <div className="section-content">
        <div className="jobs">
          {jobs.map((value, index) => (
            <div className="job" key={index}>
              <div className="time-place">
                <div className="job-company">
                  <a href={value.website} target="_blank" rel="noreferrer">
                    {value.company}
                  </a>
                </div>
                <div className="job-time">{value.duration}</div>
              </div>
              <div className="job-position">{value.position}</div>
            </div>
          ))}
        </div>
        <a
          href="https://resume.adityapradhan.dev/"
          target="_blank"
          rel="noreferrer"
          className="highlight-link"
        >
          View My Resume
        </a>
      </div>
    </div>
  );
}
