export default function Skills() {
  const skills = [
    {
      parentItem: "Languages",
      children: ["JavaScript", "Typescript", "HTML", "CSS/SCSS"],
    },
    { parentItem: "Frameworks", children: ["Angular", "React", "Highcharts"] },
    {
      parentItem: "Tools",
      children: [
        "Git & Github",
        "Chrome DevTools",
        "Postman",
        "VS Code",
        "Bash",
      ],
    },
  ];

  return (
    <div className="section skills">
      <div className="section-title">Skills</div>
      <div className="section-content">
        <div className="skill">
          {skills.map((value, index) => (
            <div className="skill-cateogry" key={index}>
              <div className="skill-category-label">{value.parentItem}</div>
              {value.children.map((childValue, childIndex) => (
                <ul key={childIndex}>
                  <li className="skill-category-item">{childValue}</li>
                </ul>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
