import { useEffect, useState } from "react";
import { DarkModeToggle } from "./components/dark-mode-toggle";

import Footer from "./components/footer";
import Experience from "./components/experience";

import "./style/style.scss";
import Skills from "./components/skills";
import Articles from "./components/articles";

function App() {
  const [theme, setTheme] = useState("light");

  const onUpdateTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    window.localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    const savedTheme = window.localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  return (
    <div className="layout" data-theme={theme === "dark" ? "dark" : "light"}>
      {/* Theme Switch Section */}
      <div className="switch-wrapper">
        <DarkModeToggle onUpdateTheme={onUpdateTheme} theme={theme} />
      </div>

      {/* Header Section */}
      <header className="intro">
          <h1 className="intro-hello">Hello <span className="emoji wave-hand animated"></span></h1>
        <h2 className="intro-tagline">
          I'm <span>Aditya Pradhan</span>, a front-end software engineer focused
          on building web applications. <span className='emoji technologist'></span>
        </h2>

        <h3 className="intro-contact">
          <span>Get in touch <span className="emoji finger-pointer"></span> </span>
          <span>
            <a
              href="mailto: adityaa.pradhan@outlook.com"
              target="_blank"
              className="highlight-link"
              rel="noreferrer"
            >
              adityaa.pradhan@outlook.com
            </a>
          </span>
        </h3>
      </header>

      {/* Background Section */}
      <div className="section background">
        <div className="section-title">Background</div>
        <div className="section-content">
          <p>
            I'm currently an Engineer at{" "}
            <a
              href="https://www.truminds.com"
              target="_blank"
              className="underline-link"
              rel="noreferrer"
            >
              Truminds Software Systems
            </a>{" "}
            building things for the web with some awesome people. I did my under
            grad from{" "}
            <a
              href="https://www.gehu.ac.in"
              target="_blank"
              className="underline-link"
              rel="noreferrer"
            >
              Graphic Era Hill University.
            </a>{" "}
            Commencing my professional journey in 2018, I swiftly immersed
            myself in front-end development, utilizing{" "}
            <span className="underline-link">HTML, CSS</span> and{" "}
            <span className="underline-link">JavaScript</span> to craft
            captivating digital experiences.
          </p>

          <p>
            As my journey progressed, I honed my skills in advanced styling
            techniques using <span className="underline-link">SASS/SCSS</span>,
            and proficiently navigated popuplar front-end frameworks like{" "}
            <span className="underline-link">Angular</span> and{" "}
            <span className="underline-link">React</span>. Additionally, I
            leveraged <span className="underline-link">Figma</span> for
            efficient design collaboration and prototyping. Currently, I lead a
            talented team through complex projects, drawing upon my technical
            expertise and leadership capabilities to achieve exceptional
            results.
          </p>
        </div>
      </div>

      {/* Skills */}
      <Skills />

      {/* Experience */}
      <Experience />

      {/* Articles */}
      <Articles username='pradhanadi' />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;
