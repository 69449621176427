import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub, faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

function Footer() {
  const social = [
    {
      name: "LinkedIn",
      iconName: faLinkedin,
      link: "https://www.linkedin.com/in/pradhanadi/",
    },
    {
      name: "Github",
      iconName: faGithub,
      link: "https://github.com/aditya-pradhann",
    },
    {
      name: "Email",
      iconName: faEnvelope,
      link: "mailto:adityaa.pradhan@outlook.com",
    },
  ];

  return (
    <footer className="footer">
      <div className="footer-copyright">
        <div className="top">
          <span>Designed & Developed By </span>
        </div>
        <div className="bottom">
          <span>
            {" "}
            Aditya Pradhan <span className="emoji victory"></span>
          </span>
          <span> {new Date().getFullYear()}</span>
        </div>
      </div>
      <div className="footer-links">
        {social.map((value, index) => (
            <a key={index} href={value.link} target="_blank" rel="noreferrer">
              <span className="text">{value.name}</span>
              <span>
                <FontAwesomeIcon icon={value.iconName} size="2xl" />
              </span>
            </a>
        ))}
      </div>
    </footer>
  );
}

export default Footer;
